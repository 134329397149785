.mike-header {
    position: sticky;
    top: 0px;
    background: #ffffff;
    z-index: 100;
    -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.10); 
    -moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.10); 
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.10); 
}

.mike-header-container {
    padding: 5px;
}

.toggle-container {
    display: inline-block;
    cursor: pointer;
    padding: 0px 10px;
}
  
.bar1, .bar2, .bar3 {
    width: 35px;
    height: 5px;
    background-color: #333;
    margin: 6px 0;
    transition: 0.4s;
}
  
.change-bar1 {
    -webkit-transform: rotate(-45deg) translate(-9px, 6px);
    transform: rotate(-45deg) translate(-9px, 6px);
}
  
.change-bar2 {
    opacity: 0;
}
  
.change-bar3 {
    -webkit-transform: rotate(45deg) translate(-8px, -8px);
    transform: rotate(45deg) translate(-8px, -8px);
}

.logo-container {
    display: inline-block;
    position: relative;
    top: -8px;
    padding: 0px 5px;
}

.logo-heading {
    font-size: 30px;
    font-weight: 600;
    color: #4285f4;
}

.mike-sidebar-container {
    /* display: block; */
    width: 100%;
    position: absolute;
    -webkit-transition: width 0.4s ease-in-out;
       -moz-transition: width 0.4s ease-in-out;
        -ms-transition: width 0.4s ease-in-out;
         -o-transition: width 0.4s ease-in-out;
            transition: width 0.4s ease-in-out;
}

.mike-sidebar-container-hide {
    /* display: none; */
    width: 0;
    position: absolute;
    -webkit-transition: width 0.4s ease-in-out;
       -moz-transition: width 0.4s ease-in-out;
        -ms-transition: width 0.4s ease-in-out;
         -o-transition: width 0.4s ease-in-out;
            transition: width 0.4s ease-in-out;
}

.side-bar {
    display: block;
    width: 250px;
    height: calc(100vh - 58px); 
    overflow-x: hidden;
    overflow-y: auto;
    background: #001529;
    border: 1px solid #ddd;
}

.side-bar-list {
    cursor: pointer;
    text-align: left;
}

.side-bar-list:hover, .side-bar-list:focus, .side-bar-list:active {
    background: #4285f4;
}

.list-items {
    display: block;
    padding: 15px 30px;
    text-decoration: none;
    color: #fff;
    font-size: 15px;
    font-weight: 400;
}

.list-items-active {
    background: #4285f4;
}

.side-bar-backdrop {
    position: fixed;
    top: 57px;
    right: 0;
    bottom: 0;
    left: 250px;
    z-index: 100;
    height: 100%;
    background-color: rgba(0,0,0,.45);
}