.manage-profile-container {
    padding: 10px;
}

.manage-profile-dropdown {
    display: inline-block;
    padding: 5px;
}

.manage-profile-dropdown select {
    width: 150px;
    height: 30px;
}

.mark-container {
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    padding: 10px;
    border: 1px solid #e7e7e7;
    background: #ddd
}
.mark-profile-section{
    display: flex;
    flex-direction: row;
    justify-content:space-evenly;
}
.mark-container-profile {
    margin-top: 15px;
    margin-left: 20px;
    margin-right: 0px;
    width: 40%;
    padding: 10px;
    border: 1px solid #e7e7e7;
    background: #ddd
}

.mark-list-profile button{
    margin-left:100px;
    padding: 10px;
        border-radius: 5px;
        background-color: #4285f4;
        border: 1px solid #4285f4;
        text-align: center;
        color: #fff;
        cursor: pointer;
}

.upload-status-profile{
    margin-top: 20px;
    width: 30%;
    border: 1px solid #e7e7e7;
}

.mark-list {
    margin: 10px 0px;
    width: 100%;
}

.mark-list button{
    padding: 10px;
    border-radius: 5px;
    background-color: #4285f4;
    border: 1px solid #4285f4;
    text-align: center;
    color: #fff;
    cursor: pointer;
}

.mark-list-item {
    display: inline-block;
    position: relative;
    padding: 5px;
}

.mark-list-item label {
    font-weight: 600;
}

.mark-list-item textarea {
    width: 295px;
    height: 50px;
    resize: none;
}

.mark-list-item select {
    width: 300px;
    height: 30px;
}

.mark-list-item input[type="checkbox"] {
    width: 20px;
    height: 20px;
}

.mark-list-item input {
    width: 292px;
    height: 30px;
}

.mark-list-item button {
    padding: 10px;
    border-radius: 5px;
    background-color: #4285f4;
    border: 1px solid #4285f4;
    text-align: center;
    color: #fff;
    cursor: pointer;
}

.mark-list-item:first-child {
    width: 30%;
    text-align: right;
}

.mark-list-item:last-child {
    width: 60%;
    text-align: left;
}

.mark-list-item-dropdown {
    position: absolute;
    z-index: 100;
    width: 300px;
    height: 150px;
    overflow: auto;
    border: 1px solid #000;
    border-radius: 2px;
    padding: 5px;
    background: #fff;
}

.dropdown-list {
    cursor: pointer;
    margin: 5px;
}

.dropdown-list:hover, .dropdown-list:focus, .dropdown-list:active {
    background: #00ddff;
}

.dropdown-list-active {
    background: #00ddff;
}

.dropdown-list-item {
    display: inline-block;
    cursor: pointer;
    padding: 5px;
}

.search-button {
    height: 47px;
    border-radius: 0 !important;
    padding: 10px 15px !important;
}

.search-button-position {
    position: absolute !important;
    left: 305px !important;
    top: 5px !important;
}

.tag-container {
    padding: 5px 0px;
}

.tag {
    padding: 5px 10px;
    background: #0096fb;
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 10px;
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    color: #fff;
    white-space: nowrap;
}

.tag-name {
    padding: 0px 5px;
    font-size: 14px;
}

.tag-close {
    font-size: 12px;
    font-weight: bold;
    float: right;
    margin-left: 2px;
    cursor: pointer;
}