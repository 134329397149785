.App {
	text-align: center;
}

.App-logo {
	animation: App-logo-spin infinite 20s linear;
	height: 40vmin;
	pointer-events: none;
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.Header {
    position: sticky;
    top: 0px;
    background: #ffffff;
    border: 1px solid #ddd;
    z-index: 1;
    padding: 15px;
    height: 5vh;
}

.header-list {
    list-style: none;
    display: inline-flex;
    margin: 0px;
    padding: 0px;
}

.Nav-Link{
    margin: 10px;
    text-decoration: none;
    color: #000;
    font-size: 18px;
    font-weight: 600;
}

.nav-active{
    color: #4285f4;
}

.Main{
    margin: 10px 0px 50px 0px;
}

.Table{
	width: 100%;
}

.Table-Title{
    position: sticky;
    /* top: 8%; */
    top: 9vh;
	background-color: #ffffff;
}

.Table-Head{
	font-size: 14px;
	background-color: #000000;
	color: #ffffff;
    position: sticky;
    /* top: 40px; */
    top: 13vh;
}

.Table-Body-Cell{
	font-size: 12px;
	background-color: #dfdfdf;
}

.No-Record-Row{
	font-weight: 700;
}


/* common-css */
.pad-0 {
	padding: 0px !important;
}

.pad-l-0 {
	padding-left: 0px !important;
}

.pad-r-0 {
	padding-right: 0px !important;
}

.pad-lr-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.pad-tb-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.pad-l-r-15{
    padding-left: 15px !important;
    padding-right: 15px !important;
}
  
.pad-t-b-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}

.p-lr-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
}
  
.p-r-5 {
    padding-right: 5px !important;
}

.p-l-5 {
    padding-left: 5px !important;
}

.pad-top-5 {
    padding-top: 5px!important;
}

.pad-top-10 {
    padding-top: 10px!important;
}

.pad-top-20 {
    padding-top: 20px!important;
}

.pad-top-50 {
    padding-top: 50px!important;
}

.pad-top-100 {
    padding-top: 100px!important;
}

.m-0 {
    margin: 0px !important;
}

.m-top-5 {
	margin-top: 5px !important;
} 
.m-top-10{
    margin-top: 10px;
}

.m-top-20 {
    margin-top: 20px!important;
}

.m-top-50 {
    margin-top: 50px!important;
}

.m-top-100 {
	margin-top: 100px !important;
}

.m-bot-5 {
	margin-bottom: 5px !important;
}

.m-bot-10 {
	margin-bottom: 10px !important;
}

.m-bot-20 {
	margin-bottom: 20px !important;
}

.w_5p {
    width: 5% !important;
}

.w_10p {
    width: 10% !important;
}

.w_20p {
    width: 20% !important;
}

.w_50p {
    width: 50% !important;
}

.w_100p {
    width: 100% !important;
}

.alignC {
	text-align: center !important;
}

.alignL {
	text-align: left !important;
}

.alignR {
	text-align: right !important;
}

.floatL {
	float: left !important;
}

.floatR {
	float: right !important;
}


.display_none{
    display: none !important;
}

.display_block{
    display: block !important;
}

.visible {
    visibility: visible !important;
}

.not-visible {
    visibility: hidden !important;
}

.disable-btn {
    cursor: default;;
    opacity: 0.5;
    pointer-events: none !important;
}

.cursor_pointer {
    cursor: pointer;
}

.cursor_default {
    cursor: default
}

.line-br-1x {
    width: 100%;
    height: 1px;
    background: #e6e6e6;
}

.line{
    width: 96%;
    height: 1px;
    background: #e7e7e7;
}

.container_body {
    display: block;
    width: 100%;
    height: auto;
    overflow-x: hidden;
    overflow-y: auto;
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;
}


.container_div {
    display: block;
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    float: left;
    margin-left: auto;
    margin-right: auto;
}

.font-weight-bold {
    font-weight: 700!important;
}


/* loader */
.backdrop-position {
    height: 100%;
    width: 100%;
    border: 1px solid;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10005;
}

.backdrop{
    height: 100%;
    width: 100%;
    background-color: #000;
    opacity: 0.7;
    z-index: 10006;
}

.backdrop-img {
    position: absolute;
    top: 45vh;
    left: 46%;
    z-index: 10003;
    text-align: center;
}

.backdrop-img >img {
    width: 35%;
}

/* scroll horizontal */
.horizontal_scroll::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.horizontal_scroll::-webkit-scrollbar-thumb {
    background: rgba(0,0,0,.3);
    box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    cursor: pointer;
}

.horizontal_scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px rgba(0,0,0,.3);
}
  
/* scroll vertical */
.vertical_scroll::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.vertical_scroll::-webkit-scrollbar-thumb {
    background: rgba(0,0,0,.3);
    box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    cursor: pointer;
}

.vertical_scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px rgba(0,0,0,.3);
}

.fw_500 { 
    font-weight: 500;
}

.fw_600 { 
    font-weight: 600;
}

.fw_bold { 
    font-weight: bold;
}

.singin-box{
    width: 50%;
    margin: auto;
    border: 1px solid #e7e7e7;
}

.singin-list {
    list-style: none;
    text-align: center;
    padding: 20px;  
}

.singin-list> li {
    padding: 10px
}

.singin-list> li> label {
    font-weight: 500;
}


.whats-New-box{
    width: 50%;
    margin: auto;
    border: 1px solid #e7e7e7;
    background: #e7e7e7;
}

.whats-New-heading{
    font-size: 20px;
    font-weight: bold;
    margin: 10px;
    text-align: center;
}

.whats-New-text{
    font-size: 15px;
    font-weight: 500;
    color: #4caf50;
    margin: 10px;
    text-align: center;
}

.whats-New-list {
    list-style: none;
    text-align: center;
    padding: 20px;  
    margin: 0px;
}

.whats-New-list> li {
    padding: 10px
}

.whats-New-list> li> div:first-child {
    display: inline-block;
    width: 35%;
    text-align: right;
} 

.whats-New-list> li> div:last-child {
    display: inline-block;
    width: 65%;
    text-align: left;
}  

.whats-New-list> li> div> label {
    font-weight: 500;
    margin: 0px 10px ;
}

.whats-new-input {
    width: 250px;
    height: 30px;
    text-indent: 5px;
    margin: 0px 10px ;
}

.whats-new-area {
    resize: none;
    width: 250px;
    height: 100px;
    text-indent: 5px;
    margin: 0px 10px ;
}

.whats-new-button{
    width: 100px;
    height: 30px;
    border-radius: 5px;
    background-color: #4285f4;
    border: 1px solid #4285f4;
    color: #fff;
    cursor: pointer;
}

.hidden {
    visibility: hidden;
}

.image-search{
    width: 100%;
    margin: auto;
    border: 1px solid #e7e7e7;
    background: #eeeeee;
    color: #222831;
}

.image-search-heading{
    font-size: 20px;
    font-weight: bold;
    margin: 10px;
    text-align: center;
}

.image-search-text{
    font-size: 15px;
    font-weight: 500;
    color: #4caf50;
    margin: 10px;
    text-align: center;
}

.image-search-list {
    list-style: none;
    text-align: center;
    padding: 20px;  
    margin: 0px;
}

.image-search-list> li {
    padding: 10px
}

.image-search-list> li> div:first-child {
    display: inline-block;
    width: 35%;
    text-align: right;
} 

.image-search-list> li> div:last-child {
    display: inline-block;
    width: 65%;
    text-align: left;
}  

.image-search-list> li> div> label {
    font-weight: 500;
    margin: 0px 10px ;
}

.image-search-input {
    width: 250px;
    height: 30px;
    text-indent: 5px;
    margin: 0px 10px ;
}

.image-search-area {
    resize: none;
    width: 250px;
    height: 100px;
    text-indent: 5px;
    margin: 0px 10px ;
}

.image-search-button{
    width: 100px;
    height: 30px;
    border-radius: 5px;
    background-color: #4285f4;
    border: 1px solid #4285f4;
    color: #fff;
    cursor: pointer;
}

.image-search-result-img{
    height: 120px;
    width: 120px;
}

/* scroll horizontal */
.horizontal_scroll::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.horizontal_scroll::-webkit-scrollbar-thumb {
    background: rgba(0,0,0,.3);
    box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    cursor: pointer;
}

.horizontal_scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px rgba(0,0,0,.3);
}
  
/* scroll vertical */
.vertical_scroll::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.vertical_scroll::-webkit-scrollbar-thumb {
    background: rgba(0,0,0,.3);
    box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    cursor: pointer;
}

.vertical_scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px rgba(0,0,0,.3);
}

.iris-btn {
    padding: 5px;
    border-radius: 5px;
    background-color: #4285f4;
    border: 1px solid #4285f4;
    text-align: center;
    color: #fff;
    cursor: pointer;
}

.iris-select {
    width: 230px;
    height: 30px;
}