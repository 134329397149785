.latest-journal-box {
    width: 100%;
    margin: auto;
    /* border: 1px solid  #000;  */
}

.latest-journal-list {
    list-style: none;
    text-align: left;
    padding: 0px 20px;  
}

.latest-journal-list> li> label {
    font-weight: 500;
}